<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-simple-table fixed-header>
    <template>
      <thead>
      <tr>
        <th>
          {{ clientAdmin ? 'Distributor/Customer' : (distributor ? 'Customer' : 'Distributor') }}
        </th>
        <th>
          Site & Zone
        </th>
        <th>
          Status
        </th>
        <th v-if="full">
          Config
        </th>
        <th v-if="full">
          TAG ID/ref
        </th>
        <th>

        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="cursor"
          v-for="item in items"
          :key="item.id"
          @click="() => { $router.push({name : 'HoseDetail',params: { hoseId : item.id }}) }"
      >
        <td v-if="clientAdmin">
          <strong>Distributor:</strong> {{ item.distributorName }}<br/>
          <strong>Customer:</strong> {{ item.customerName }}
        </td>
        <td v-else>
          {{ distributor ? item.customerName : item.distributorName }}
        </td>
        <td>
                    <span v-if="item.organisationZoneName">
                        <site-dropdown :title="item.organisationZoneName" :id="item.organisationZoneId"/>
                    </span>
          <strong v-else>
            Uninstalled
          </strong>
        </td>
        <td>
          <hose-status class="my-2" :status="item.status"></hose-status>
          <small class="mb-2 mx-1 font-weight-bold d-block" v-if="item.status === 0 || item.status === 1">
            <v-badge v-if="item.replacementWarning" color="error" :inline="true" :dot="true"></v-badge>
            Due : {{ $options.filters.formatDate(item.nextInspectionDate) }}
          </small>
        </td>
        <td v-if="full">
          <template v-if="item.hoseConfigurationName">

            <div class="font-weight-bold">{{ item.hoseConfigurationName }}</div>

            <hose-configuration-dropdown
                :id="item.hoseConfigurationId"></hose-configuration-dropdown>

          </template>
        </td>

        <td v-if="full">
          <div class="caption">
            #{{ item.id }}
          </div>
          <div v-if="item.name" class="caption">
            Ref : {{ item.name }}
          </div>
        </td>

        <td class="text-right ">
          <action-dropdown :items="getDropdown(item)"/>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import moment from "moment";
import HoseConfigurationView from "../hose-configurations/HoseConfigurationView";
import HoseConfigurationDropdown from "../hose-configurations/HoseConfigurationDropdown";
import HoseStatus from "./HoseStatus";
import SiteDropdown from "../sites/SiteDropdown";

export default {
  name: 'HoseList',
  components: {SiteDropdown, HoseStatus, HoseConfigurationDropdown, HoseConfigurationView, ActionDropdown},
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      hoseConfigOpen: false,
      badgeClass: 'd-inline-block rounded mt-1 py-1 px-2 font-weight-bold text-sm-left'
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor']),

  },
  methods: {
    lastInspection(item) {
      return `<div>` + (item.lastInspectionDate ? "Inspected by " + item.lastInspectionName + " on " + moment(item.lastInspectionDate).format("DD/MM/yyyy") : "Never inspected") + `<div>`
    },
    nextInspection(item) {
      if (item.nextInspectionDate) {
        var divClass = "";
        var divText = "";
        var date = moment(item.nextInspectionDate);
        var currentDate = moment().utc();
        var soonDate = currentDate.subtract(1, "month");
        if (currentDate.isSameOrAfter(date)) {
          divClass = "error white--text";
          divText = "Inspection was due on " + date.format("DD/MM/yyyy");
        } else if (currentDate.isBefore(soonDate) && currentDate.isSameOrAfter(date)) {
          divClass = "warning white--text";
          divText = "Inspection due on " + date.format("DD/MM/yyyy");
        } else {
          divClass = "light";
          divText = "Inspection due on " + date.format("DD/MM/yyyy");
        }
        return `<span class="` + this.badgeClass + ` ` + divClass + `">` + divText + `</span>`
      }
      return "";
    },
    getDropdown(item) {
      var data = [
        {
          label: 'View full details',
          icon: 'mdi-gesture',
          route: {name: 'HoseDetail', params: {hoseId: item.id}}
        }
      ];
      if (!this.clientAdmin && item.hoseConfigurationName != null) {
        data.push({
          label: this.distributor ? 'Offer quote' : 'Request quote',
          icon: 'mdi-clipboard-check-outline',
          route: {name: 'HoseQuote', params: {hoseId: item.id}}
        });
      }
      return data;
    }
  }
}
</script>
