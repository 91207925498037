<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page title="Organisation settings">
    <v-row align="stretch">
      <v-col cols="12" class="d-flex flex-column">
        <h2 class="card-header mb-2">Business information</h2>
        <v-card class="pa-3 py-5 flex-fill d-flex flex-column justify-end">
          <v-row>
            <v-col cols="12" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Organisation name"
                            v-model="item.name"

                            :error="errors.name != null"
                            :error-messages="errors.name"
              />
            </v-col>
            <v-col cols="12" :lg="4" :md="6" :sm="12">
              <v-text-field dense label="Contact name"
                            v-model="item.contactName"

                            :error="errors.contactName != null"
                            :error-messages="errors.contactName"/>
            </v-col>
            <v-col cols="12" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Contact email"
                            v-model="item.contactEmail"

                            :error="errors.contactEmail != null"
                            :error-messages="errors.contactEmail"/>
            </v-col>
            <v-col cols="12" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Address line 1"
                            v-model="item.addressLine1"
                            :error="errors.addressLine1 != null"
                            :error-messages="errors.addressLine1"/>
            </v-col>
            <v-col cols="12" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Address line 2"
                            v-model="item.addressLine2"
                            :error="errors.addressLine2 != null"
                            :error-messages="errors.addressLine2"/>
            </v-col>
            <v-col cols="12" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Town/City"
                            v-model="item.town"
                            :error="errors.town != null"
                            :error-messages="errors.town"/>
            </v-col>
            <v-col cols="12" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Post code"
                            v-model="item.postCode"
                            :error="errors.postCode != null"
                            :error-messages="errors.postCode"/>
            </v-col>
          </v-row>

        </v-card>
        <template v-if="customer">
          <h2 class="card-header mb-2 mt-4">System setup</h2>
          <v-card class="pa-3 py-5">
            <v-row>
              <v-col cols="12">
                <div class="rounded bordered light pa-3">
                  <v-checkbox
                      :dense="true"
                      label="Enable hose configuration"
                      v-model="item.configurationsVisible"
                      hint=""
                      :persistent-hint="true"
                  />
                  <v-alert class="mb-0" type="info">
                    If this setting is disabled, only connected distributors will be able to manage your hoses and
                    users will have read-only access.
                  </v-alert>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" :md="4">
                <v-text-field dense
                              label="Inspection timeline"
                              persistent-hint
                              type="number"
                              suffix="months"
                              hint="The frequency your organisation require inspections"
                              v-model="item.hoseInspectionMonths"
                              :error="errors.hoseInspectionMonths != null"
                              :error-messages="errors.hoseInspectionMonths"/>
              </v-col>
              <v-col cols="12" :md="4">
                <v-text-field dense
                              label="Replacement timeline"
                              persistent-hint
                              suffix="months"
                              type="number"
                              hint="Hoses installed for more than this time will be due for replacement"
                              v-model="item.hoseReplacementMonths"
                              :error="errors.hoseReplacementMonths != null"
                              :error-messages="errors.hoseReplacementMonths"/>
              </v-col>
              <v-col cols="12" :md="4">
                <v-text-field dense
                              label="Replacement warning"
                              persistent-hint
                              suffix="months"
                              type="number"
                              hint="Hoses due for replacement will be marked when their replacement is due in this many months"
                              v-model="item.hoseReplacementWarningMonths"
                              :error="errors.hoseReplacementWarningMonths != null"
                              :error-messages="errors.hoseReplacementWarningMonths"/>
              </v-col>
            </v-row>

          </v-card>
        </template>
      </v-col>
      <v-col cols="12" :lg="4" :md="6" :sm="12" class="d-flex flex-column">
        <h2 class="card-header mb-2">Optional fields</h2>
        <v-card class="pa-3 flex-fill" style="max-height: 480px;">
          <v-alert class="mb-6" color="light">
            Select which additional fields are either optional, not shown or required within hose configurations.
          </v-alert>
          <div v-for="field in fields">
            <v-select
                dense
                class="flex-wrap mb-3"
                item-value="value"
                item-text="label"
                :label="field.name"
                :items="field.key === 'manufacturer' || field.key === 'manufactureDate' ? fieldOptions : fieldOptionsCheck"
                :value="fieldModel(field.key)"
                @change="(val) => updateField(field.key,val)"
            ></v-select>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" :lg="4" :md="6" :sm="12" class="d-flex flex-column">
        <h2 class="card-header mb-2">Hose types</h2>
        <v-card class="flex-fill overflow-auto" style="max-height: 480px;">
          <v-alert class="ma-3" color="light">
            Select which hose types are more prominent. Those marked with “Y” will be shown at the top, those marked “N”
            will move to the bottom.
          </v-alert>
          <v-list dense class="pa-0">
            <div class="px-3 py-0 d-flex flex-row">
              <div class="flex-fill"></div>
              <div class="flex-wrap mr-1">
                <strong class="mx-3">Y</strong>
                <strong class="mx-3">N</strong>
              </div>
            </div>
            <template v-for="(c,cIndex) in hoseTypes">
              <v-list-item :key="c.id">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>{{ c.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-radio-group
                      dense
                      class="flex-wrap"
                      :value="hoseTypeModel(c.id)" @change="updateHoseType(c.id)"
                      row
                  >
                    <v-radio
                        class="ma-0"
                        :value="0"
                    ></v-radio>
                    <v-radio
                        class="ma-0"
                        :value="1"
                    ></v-radio>
                  </v-radio-group>
                </template>
              </v-list-item>
              <v-divider
                  v-if="cIndex < hoseTypes.length - 1"
                  :key="cIndex"
              ></v-divider>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" :lg="4" :md="6" :sm="12" class="d-flex flex-column">
        <h2 class="card-header mb-2">Manufacturers</h2>
        <v-card class="flex-fill overflow-auto" style="max-height: 480px;">
          <v-alert class="ma-3" color="light">
            Select which manufacturers are more prominent. Those marked with “Y” will be shown at the top, those marked
            “N” will move to the bottom.
          </v-alert>
          <v-list dense class="pa-0">
            <div class="px-3 py-0 d-flex flex-row">
              <div class="flex-fill"></div>
              <div class="flex-wrap mr-1">
                <strong class="mx-3">Y</strong>
                <strong class="mx-3">N</strong>
              </div>
            </div>
            <template v-for="(c,cIndex) in manufacturers">
              <v-list-item :key="c.id">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>{{ c.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-radio-group
                      dense
                      class="flex-wrap"
                      :value="manufacturerModel(c.id)" @change="updateManufacturer(c.id)"
                      row
                  >
                    <v-radio
                        class="ma-0"
                        :value="0"
                    ></v-radio>
                    <v-radio
                        class="ma-0"
                        :value="1"
                    ></v-radio>
                  </v-radio-group>
                </template>
              </v-list-item>
              <v-divider
                  v-if="cIndex < manufacturers.length - 1"
                  :key="cIndex"
              ></v-divider>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-right mt-3">
      <global-error-inline :errors="errors"></global-error-inline>
      <v-btn :loading="loading" @click="saveAction" color='success' depressed>
        <v-icon small class="mr-1">mdi-content-save-outline</v-icon>
        Save settings
      </v-btn>
    </div>
  </crud-page>
</template>

<script>

import CrudPage from "../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import {endpoints, httpRequest} from "../../store/network/hitag.client";
import GlobalErrorInline from "../../components/GlobalErrorInline";

export default {
  name: 'OrganisationSettings',
  components: {
    GlobalErrorInline,
    CrudPage
  },
  computed: {
    ...mapGetters('auth', ['organisation', 'customer']),
    ...mapGetters('config', ['connectionTypes', 'manufacturers', 'hoseTypes']),
  },
  created() {
    this.forceReload()
  },
  methods: {
    ...mapActions('config', ['forceReload']),
    fieldModel(field) {
      return this.selectedFields[field] ?? 0;
    },
    updateField(field, value) {
      Object.defineProperty(this.selectedFields, field, {
        value: value,
        writable: true,
        enumerable: true,
        configurable: true
      });
    },
    manufacturerModel(id) {
      if (this.selectedManufacturers.indexOf(id) !== -1) {
        return 1;
      }
      return 0;
    },
    updateManufacturer(id) {
      var index = this.selectedManufacturers.indexOf(id);
      if (index > -1) {
        this.selectedManufacturers.splice(index, 1);
      } else {
        this.selectedManufacturers.push(id);
      }
    },
    hoseTypeModel(id) {
      if (this.selectedHoseTypes.indexOf(id) !== -1) {
        return 1;
      }
      return 0;
    },
    updateHoseType(id) {
      var index = this.selectedHoseTypes.indexOf(id);
      if (index > -1) {
        this.selectedHoseTypes.splice(index, 1);
      } else {
        this.selectedHoseTypes.push(id);
      }
    },
    async saveAction() {
      this.loading = true;
      this.errors = {};
      try {
        var data = this.item;
        data.updateConfig = true;
        data.manufacturers = this.selectedManufacturers;
        data.hoseTypes = this.selectedHoseTypes;
        data.fields = this.selectedFields;
        console.log("UPLOAD DATA IS", data.fields);
        let res = await httpRequest(endpoints.organisations.updateItem, data);
        await this.$store.dispatch("auth/me", res.data);
      } catch (e) {
        this.errors = e.response.data;
      } finally {
        this.loading = false;
      }
    }
  },
  data() {
    return {
      selectedHoseTypes: [],
      selectedManufacturers: [],
      selectedFields: {},
      loading: false,
      item: {},
      errors: {},
      fieldOptions: [
        {label: "Optional", value: 0},
        {label: "Don't show", value: 1},
        {label: "Required", value: 2},
      ],
      fieldOptionsCheck: [
        {label: "Optional", value: 0},
        {label: "Don't show", value: 1},
      ],
      fields: [{
        name: "Manufacturer",
        key: "manufacturer",
      },
        {
          name: "Manufacture date",
          key: "manufactureDate",
        },
        {
          name: "Sleeving",
          key: "sleeving",
        },
        {
          name: "Test certificate",
          key: "testCertificate",
        },
        {
          name: "Certificate of conformity",
          key: "conformityCertificate",
        }
      ]
    }
  },
  watch: {
    organisation: {
      immediate: true,
      handler(newVal) {
        console.log("ORGANISATION IS ", newVal);
        this.item = newVal;
        this.selectedFields = newVal.fields ?? {};
        this.selectedManufacturers = newVal.manufacturers ?? [];
        this.selectedHoseTypes = newVal.hoseTypes ?? [];
      }
    }
  }
}
</script>
