<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-gesture" :actions="actions" :breadcrumbs="breadcrumbs" :title="title">
    <v-row v-if="item.id">
      <v-col cols="3">
        <h2 class="card-header mb-2">
          Hose status
        </h2>
        <v-card class="pa-3">
          <hose-status :status="item.status"></hose-status>
          <small class="mt-2 mx-1 font-weight-bold d-block" v-if="item.status === 0 || item.status === 1">
            Due : {{ $options.filters.formatDate(item.nextInspectionDate) }}
          </small>

          <v-chip color="error" v-if="item.replacementWarning" class="mt-2" x-small outlined>
            Replacement due soon
          </v-chip>

        </v-card>
        <template v-if="item.organisationZoneId && !item.deletedAt">
          <h2 class="card-header mb-2 mt-3 to-commit">
            Hose location
          </h2>
          <v-card class="pa-3">
            <site-view :id="item.organisationZoneId"></site-view>
          </v-card>
        </template>
        <h2 class="card-header mb-2 mt-3">
          Hose details
        </h2>
        <v-card class="pa-3">
          <hose-configuration-view :refresh-token="refreshToken" :hose-id="hoseId"></hose-configuration-view>
        </v-card>
      </v-col>
      <v-col cols="9">
        <h2 class="card-header mb-2">
          Hose timeline
        </h2>
        <v-card>
          <v-timeline
              v-if="item.buildDate || item.installationDate || meta.length > 0"
              align-top
              dense
          >
            <hose-timeline-item
                v-for="metaItem in meta"
                :color="metaItem.color"
                v-bind:key="'meta'+metaItem.id"
                :title="metaItem.title"
                :subtitle="$options.filters.formatDate(metaItem.date)"
                :text="metaItem.text"
                :uploads="metaItem.uploads"
            >
              <template>
                <hose-status class="mt-2 d-inline-block" :status="metaItem.inspectionResult"/>
              </template>
            </hose-timeline-item>

          </v-timeline>
          <div v-else class="pa-5">
            No history for this hose at present
          </div>
        </v-card>
        <h2 class="card-header mb-1 mt-4">
          Hose documents
        </h2>
        <v-card class="pa-3">
          <v-alert color="light" v-if="!item.uploads || item.uploads.length === 0">
            No documents uploaded to this hose
          </v-alert>
          <div class="light-border pa-3 mb-2 rounded align-center justify-space-between d-flex" v-for="upload in item.uploads">
            <v-icon color="info flex-wrap">{{ $options.filters.fileTypeToIcon(upload.mimetype) }} </v-icon>
            <div class="flex-fill ml-2">
              <div><strong>{{ upload.filename }}</strong></div>
              <div><small >{{ "Uploaded on "+$options.filters.formatDate(upload.createdAt) }}</small></div>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" class="flex-wrap mr-3 error" @click="deleteUpload(upload)" icon><v-icon color="white">mdi-delete</v-icon></v-btn>
              </template>
              <span>Delete document</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" class="flex-wrap info" v-if="upload.signedLink" :href="upload.signedLink" icon><v-icon>mdi-download</v-icon></v-btn>
              </template>
              <span>Download document</span>
            </v-tooltip>
          </div>
          <v-file-input
              v-model="uploadFile"
              :loading="uploadLoading"
              accept="image/png,image/jpg,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel"
              label="Select a document to upload"
          ></v-file-input>
          <div class="font-weight-bold">
            <v-btn depressed small :loading="uploadLoading" color="success" :disabled="!uploadFile" @click="processUpload">Upload file</v-btn>
            <small class="ml-2">Images, PDFs, Word and Excel files are supported</small>
          </div>
        </v-card>
      </v-col>
    </v-row>

  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import HoseConfigurationView from "../../../components/hose-configurations/HoseConfigurationView";
import HoseTimelineItem from "./HoseTimelineItem";
import * as _ from "lodash";
import HoseStatus from "../../../components/hoses/HoseStatus";
import SiteView from "../../../components/sites/SiteView";
import emitter from "@/utils/emitter";
import {getFileType, toBase64} from "@/helpers";
import {endpoints, httpRequest} from "@/store/network/hitag.client";

export default {
  name: 'Hoses',
  props: {
    hoseId: null
  },
  data() {
    return {
      refreshToken: 0,
      uploadFile: null,
      uploadLoading : false,
      uploadErrors : {}
    }
  },
  components: {
    SiteView,
    HoseStatus,
    HoseTimelineItem,
    HoseConfigurationView,
    CrudPage
  },
  created() {
    if (this.hoseId != null) {
      console.log("GETTING ITEM ", this.hoseId);
      this.getItem(this.hoseId);
    }
    emitter.on('hoseUpdated', this.onHoseUpdate);
  },
  beforeDestroy() {
    emitter.off('hoseUpdated', this.onHoseUpdate);
  },
  watch: {
    item(newVal, oldVal) {
      this.refreshToken = Math.random();
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor','organisation']),
    ...mapGetters('hoses', ['item', 'formLoading', 'formError']),
    actions() {
      var list = [];
      if (this.item.deletedAt == null) {
        list.push({
          icon: 'mdi-trash-can',
          title: 'Archive',
          route: {name: 'HoseDelete'},
          variant: 'primary'
        });
        list.push({
          icon: 'mdi-pencil',
          title: 'Edit',
          route: {name: 'HoseEdit'},
          variant: 'info'
        });
      }
      list.push({
        icon: 'mdi-note-plus',
        title: 'Add note',
        route: {name: 'HoseNote'},
        variant: 'success'
      });
      return list;
    },
    showInstallation() {
      return this.item.installationDate && this.item.organisationZone
    },
    installationText() {
      return "Installation by " + (this.item.installationUser?.fullName ?? "Unknown user");
    },
    buildText() {
      return "Built by " + (this.item.buildUser?.fullName ?? "Unknown user");
    },
    deletedText() {
      return "Archived by " + (this.item.deletedUser?.fullName ?? "Unknown user");
    },
    loadingError() {
      return Object.keys(this.formError).length > 0;
    },
    meta() {
      var finalMeta = [];
      if (this.item.deletedAt) {
        finalMeta.push({
          id: 'delete-timeline',
          title: this.deletedText,
          text: null,
          date: this.item.deletedAt,
          uploads: [],
          color: 'error'
        })
      }
      if (this.showInstallation) {
        finalMeta.push({
          id: 'installation-timeline',
          title: this.installationText,
          text: null,
          date: this.item.installationDate,
          uploads: [],
          color: 'success'
        })
      }
      if (this.item.buildDate) {
        finalMeta.push({
          id: 'build-timeline',
          title: this.buildText,
          text: null,
          date: this.item.buildDate,
          uploads: [],
          color: 'info'
        })
      }
      finalMeta.push(...this.item.hoseMeta.map(meta => {
        return {
          id: meta.id,
          title: this.timelineTitle(meta),
          inspectionResult: meta.inspectionResult,
          text: meta.notes,
          date: meta.createdAt,
          uploads: meta.uploads,
          color: this.$options.filters.statusToColor(meta.inspectionResult)
        }
      }));
      return finalMeta.sort(function compare(a, b) {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });
    },
    title() {
      if (this.formLoading) {
        return "Loading";
      } else if (this.loadingError) {
        return "Unable to load";
      } else {
        if (this.item.name) {
          return this.item.name;
        } else if (this.item.organisationZone) {
          return this.item.organisationZone.name;
        } else if (this.item.id) {
          return "#" + this.item.id;
        }
      }
    },
    breadcrumbs() {
      return [
        {
          to: {name: 'Hoses'},
          label: "Hoses"
        }
      ]
    },
  },
  methods: {
    ...mapActions('uploads', ['deleteItem']),
    async deleteUpload(upload) {
      await this.deleteItem(upload.id);
      await this.getItem(this.hoseId);
    },
    async processUpload() {
      let upload = {
        base64Data: await toBase64(this.uploadFile),
        uploadType: getFileType(this.uploadFile),
        hoseId : this.hoseId,
        filename : this.uploadFile.name,
      };
      this.uploadLoading = true;
      this.uploadErrors = {};
      console.log("UPLOAD IS ",upload);

      try {
        let res = await httpRequest(endpoints.uploads.createItem, upload);
        await this.$store.dispatch('alerts/setUserMessage','File uploaded');
        await this.getItem(this.hoseId);
        this.uploadFile = null;
        console.log("upload success", res);
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.uploadErrors = e.response.data;
      } finally {
        this.uploadLoading = false;
      }
    },
    async onHoseUpdate() {
      await this.$router.replace({name: 'HoseDetail', params: {hoseId: this.hoseId}});
      await this.getItem(this.hoseId);
    },
    timelineTitle(item) {
      var title = item.inspectionResult !== null ? 'Inspection' : 'Note';
      if (item.user) {
        title = title + " by " + item.user.fullName;
      }
      return title;
    },
    ...mapActions('hoses', ['getItem'])
  }
}
</script>
